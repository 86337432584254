'use strict';

document.addEventListener('DOMContentLoaded', function() {
const lang = document.querySelector('html').getAttribute('lang');
const currentUrl = new URL(location.href);

/**
 * 言語切り替え
 * @variable ua ユーザーエージェント
 */
const langBtnJP = document.querySelector('.l-nav_language_item:nth-child(1)');
const langBtnEN = document.querySelector('.l-nav_language_item:nth-child(2)');

const redirectExceptionList = [
  ['/news/', '/en/#anchor-news'],
  ['/about/', '/en/#anchor-about'],
  ['/about/', '/en/#anchor-about'],
  ['/from-lab/', '/en/'],
  ['/info/', '/en/'],
  ['/seminar-document/', '/en/'],
  ['/social-media-policy/', '/en/'],
  ['/service/', '/en/'],
];

// 前方一致チェック関数、一致した場合は配列の2番目の要素を返す
const startsWithAndGetSecond = (url, list) => {
  for (let i = 0; i < list.length; i++) {
    if (url.startsWith(list[i][0])) {
      return list[i][1];
    }
  }
  return null;
}

// URLが例外リストに一致するかチェックし、一致した場合はその2番目の要素を取得
const matchedRedirect = startsWithAndGetSecond(currentUrl.pathname, redirectExceptionList);

if ( lang === 'ja' ) { // 日本語PC

  // 例外リスト処理
  if (!matchedRedirect) {
    langBtnEN.querySelector('a').setAttribute('href', '/en' + currentUrl.pathname + currentUrl.hash);
    langBtnJP.querySelector('a').setAttribute('href', currentUrl.pathname + currentUrl.hash);
  } else {
    langBtnEN.querySelector('a').setAttribute('href', matchedRedirect);
    langBtnJP.querySelector('a').setAttribute('href', currentUrl.pathname + currentUrl.hash);
  }
} else { // 英語PC
  langBtnJP.querySelector('a').setAttribute('href', currentUrl.pathname.slice(3) + currentUrl.hash);
  langBtnEN.querySelector('a').setAttribute('href', currentUrl.pathname + currentUrl.hash);
}

const langBtnJPSP = document.querySelector('.l-spNav_language_item:nth-child(1)');
const langBtnENSP = document.querySelector('.l-spNav_language_item:nth-child(2)');

if ( lang === 'ja' ) { // 日本語SP
  if (!matchedRedirect) {
    langBtnENSP.querySelector('a').setAttribute('href', '/en' + currentUrl.pathname + currentUrl.hash);
    langBtnJPSP.querySelector('a').setAttribute('href', currentUrl.pathname + currentUrl.hash);
  } else {
    langBtnENSP.querySelector('a').setAttribute('href', matchedRedirect);
    langBtnJPSP.querySelector('a').setAttribute('href', currentUrl.pathname + currentUrl.hash);
  }
} else {  // 英語SP
  langBtnJPSP.querySelector('a').setAttribute('href', currentUrl.pathname.slice(3) + currentUrl.hash);
  langBtnENSP.querySelector('a').setAttribute('href', currentUrl.pathname + currentUrl.hash);
}



/**
 * Internet Explorer閲覧非推奨（モーダル表示）
 * @variable ua ユーザーエージェント
 * @variable browserMessage 表示テキスト
 * @variable browserMessageHtml 表示テキストを含むHTML
 */
const ua = window.navigator.userAgent;
if (ua.indexOf('MSIE') != -1 || ua.indexOf('Trident') != -1) {
  let browserMessage = '';
  if ( lang === 'ja' ) {
    browserMessage = '当サイトを閲覧頂きありがとうございます。<br>当サイトのコンテンツはお客さまがご使用のブラウザには対応しておりません。<br>「Microsoft Edge」「Google Chrome」「Safari」の最新版ブラウザをご利用下さい。';
  } else {
    browserMessage = 'Thank you for visiting this website.<br>The content of this website is not viewable on your browser.<br>Please use the latest browser version of Microsoft Edge, Google Chrome, or Safari.';
  }
  const browserMessageHtml = '<div class="m-browserOverlay"></div><div class="m-browser"><div class="m-browser_container"><div class="m-browser_message">' + browserMessage +'</div></div></div>';
  document.querySelector('body').insertAdjacentHTML('afterbegin', browserMessageHtml);
  document.querySelector('html').style.overflow = 'hidden';
}


/**
 * パラメータ取得関数
 * @argument name 取得したいパラメータ名
 * @argument url 表示ページ以外のURLから取得したい場合
 */
function getParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}


/**
 * アンカースクロール
 * @variable headerElement 固定ヘッダーセレクタ
 * @variable headerElement スクロール速度
 */
const headerClass = '.l-header';

let scroll = new SmoothScroll('a[href*="#"]', {
  speedAsDuration: true,
  speed: 500,
  easing: 'easeInOutQuint'
});

// ページ表示時のアンカー位置にフレームの上辺の高さを考慮する
let headerElementHeight = 0;

// ハッシュ値があればロード時に高さを考慮した位置へスクロール位置を差し替え
if ( window.location.hash !== '' ) {
  window.addEventListener('load', function() {
    const target = document.querySelector(window.location.hash);
    if ( target !== null ) {
      const position = target.offsetTop - headerElementHeight;
      document.documentElement.scrollTop = position;
    }
  });
}

/**
 * ハンバーガーメニューの開閉処理
 * @variable lSpNavBtn ハンバーガーメニュー開閉ボタン要素
 */

const lSpNavBtn = document.querySelector('.l-spNavBtn');
const lSpNav = document.querySelector('.l-spNav');
lSpNavBtn.addEventListener('click', function() {
  lSpNavBtn.classList.toggle('is-open');
  lSpNav.classList.toggle('is-open');
});


/**
 * ヘッダーにページ内リンクがあるため、リンク押下時にハンバーガーメニューを非表示にする。
 * @variable lSpNavBtn ハンバーガーメニュー開閉ボタン要素
 */
const lSpNavListAnchor = document.querySelectorAll('.l-spNav_list_anchor');

lSpNavListAnchor.forEach( function(element) {
  element.addEventListener('click', function() {
    lSpNavBtn.classList.remove('is-open');
    lSpNav.classList.remove('is-open');
  });
});

/**
 * フッターのページトップボタンのスクロール処理
 * @variable lFooterOtherPagetop クリックボタン要素
 */
const lFooterOtherPagetop = document.querySelector('.l-footer_other_pagetop');

//クリックイベントを追加
lFooterOtherPagetop.addEventListener( 'click' , function() {
  window.scroll({top: 0, behavior: 'smooth'});
} );


var scrollHeader = document.querySelector('.l-header');
var scrollNavLanguage = document.querySelector('.l-nav_language');
var scrollHeaderLogoArrow = document.querySelector('.l-header_logo_arrow');

var scrollOffset = 500;
var lastScrollTop = 0;
var delta = 5; // Threshold to detect scroll direction

window.addEventListener('scroll', function() {
    var st = window.pageYOffset || document.documentElement.scrollTop;

    // Ensure the scroll is more than the threshold
    if(Math.abs(lastScrollTop - st) <= delta)
        return;

    if (st > lastScrollTop && st > scrollOffset) {
        // Scroll down and passed the offset
        scrollHeader.classList.add('is-hidden');
        scrollNavLanguage.classList.add('is-hidden');
        scrollHeaderLogoArrow.classList.add('is-hidden');
    } else {
        // Scroll up
        if (st + window.innerHeight < document.documentElement.scrollHeight) {
          scrollHeader.classList.remove('is-hidden');
          scrollNavLanguage.classList.remove('is-hidden');
          scrollHeaderLogoArrow.classList.remove('is-hidden');
        }
    }
    lastScrollTop = st;
});


/**
 * 縦線の長さ処理（フッターに影響しない）
 * @variable lLine 縦線の要素
 * @variable domBody body要素
 * @variable lFooter フッター要素（フッターの高さ分減算する）
 */
const lLine = document.querySelector('.l-line');
const domBody = document.querySelector('body');
const lFooter = document.querySelector('.l-footer');

lLine.style.height = domBody.clientHeight - lFooter.clientHeight + 'px';

window.addEventListener('resize', function() {
  lLine.style.height = domBody.clientHeight - lFooter.clientHeight + 'px';
});

if ( document.querySelector('.js-bg') ) {
  const jsBg = document.querySelector('.js-bg');
  jsBg.style.height = domBody.clientHeight - lFooter.clientHeight + 'px';

  window.addEventListener('resize', function() {
    jsBg.style.height = domBody.clientHeight - lFooter.clientHeight + 'px';
  });
}


/**
 * GSAPスクロールトリガー
 */
let gsapFadeUps = gsap.utils.toArray('.js-fadeUp');
gsapFadeUps.forEach( function(gsapFadeUp) {
  gsap.to(gsapFadeUp, {
    scrollTrigger: {
      trigger: gsapFadeUp, // ビューポート監視対象
      start: '20% bottom',
      once: true,
      toggleClass: { targets: gsapFadeUp, className: "is-animate" }
    }
  });
});
let gsapColorTinges = gsap.utils.toArray('.js-colorTinge');
gsapColorTinges.forEach( function(gsapColorTinge) {
  gsap.to(gsapColorTinge, {
    scrollTrigger: {
      trigger: gsapColorTinge, // ビューポート監視対象
      start: '70% bottom',
      once: true,
      toggleClass: { targets: gsapColorTinge, className: "is-animate" }
    }
  });
});

/**
 * Javascriptによるメディアクエリ
 * @variable mediaQueryList PC以上の場合
 */
const mediaQueryList = window.matchMedia('(min-width: 751px)');
handleMediaQuery(mediaQueryList);

function handleMediaQuery(e) {
  if (e.matches) {
    // min-width: 751px (PC)


  } else {
    // max-width: 750px (SP)

  }
}
mediaQueryList.addEventListener('change', handleMediaQuery);


/**
 * スマートフォン表示でブラウザUIを考慮する処理
 * @memo 下記CSSを全画面表示したい要素に設定する
 * @memo min-height: 100vh; min-height: calc(var(--vh, 1vh) * 100);
 */
const setFillHeight = function() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');
}

// 画面のサイズ変動があった時に高さを再計算する
window.addEventListener('resize', setFillHeight);

// 初期化
setFillHeight();

/**
 * アクセシビリティを考慮したスライダー
 * https://ja.splidejs.com/
 */
/*
<div class="splide js-slider" aria-label="screen-read-name">
  <div class="splide__track">
    <ul class="splide__list">
      <li class="splide__slide"></li>
    </ul>
  </div>
  <div class="splide__arrows">
    <button class="splide__arrow splide__arrow--prev">Prev</button>
    <ul class="splide__pagination"></ul>
    <button class="splide__arrow splide__arrow--next">Next</button>
  </div>
</div>
*/
if ( document.querySelector('.js-slider') !== null ) {
  let jsSlider = new Splide('.js-slider', {
    perPage       : 3,      // 1ページの表示数
    perMove       : 1,      // 1度にスライドを何枚移動するか
    speed         : 800,    // 移動時間（ミリ秒単位）
    type          : 'loop', // ループする場合
    interval      : 4000,   // 自動再生の間隔（ミリ秒単位）
    pauseOnHover  : false,  // マウスホバー時に自動再生をストップさせない
    resetProgress : false,  // 自動再生ストップ時、経過時間を破棄せず続行する
    updateOnMove  : true,   // is-activeクラスを移動前に更新する
    gap           : '30px', // スライド間の余白（cssと同様）
    padding       : '50px', // 左右の見切れる幅（cssと同様）
    easing        : 'cubic-bezier(0.25, 1, 0.5, 1)', // 移動アニメーション
    focus         : 'center',

    // インビュー時から自動再生をスタート（拡張機能：splide-extension-intersection.js）
    autoplay: 'pause',
    intersection: {
      inView: {
        autoplay: true,
      },
      outView: {
        autoplay: false,
      },
      once: true, // 一度画面に表示されたら監視を停止する
    },

    // 動き続けるスライダーを実装（拡張機能：splide-extension-auto-scroll.js）
    autoScroll: {
      speed: 1, // 負の値で流れる向きが右へ変更
      pauseOnHover: false,
      pauseOnFocus: false,
    },

    // メディアクエリ設定（初期：769px以上の場合、スライダーを動作させない）
    // mediaQuery: 'min',
    // breakpoints: {
    //   769: {
    //     destroy: true,
    //   },
    // },
  });

  // スライダーに過不足がある場合にスライダーUIを停止・排除する
  jsSlider.on('overflow', function ( isOverflow ) {
    jsSlider.go(0);
    jsSlider.options = {
      arrows    : isOverflow,
      pagination: isOverflow,
      drag      : isOverflow,
      clones    : isOverflow ? undefined : 0,
    };
  } );

  // スライダーを実行
  jsSlider.mount();
  // jsSlider.mount(window.splide.Extensions);

}


  $('.service-qa_acco_q').on('click', function() {
    $(this).toggleClass('is-active');
    $(this).next('.service-qa_acco_a').slideToggle();
  });


  if (!Cookies.get('indexStickyFlg')) {
    // クッキーが存在なければ場合は、.index-stickyを非表示にする
    $('.index-sticky').show();
  }


  $('.index-sticky_close').on('click', function() {
    $('.index-sticky').addClass('is-close');
    Cookies.set('indexStickyFlg', 'true', { expires: 3 });
  });

});

